import React, { useContext, useEffect } from 'react';
import StepBoxRadio from '../../Components/StepBoxRadio';
import { FormContext } from '../../FormContext';
import NavigationButtons from '../../Components/NavigationButtons';
import { sendEvent } from '../../Helpers/analytics';

const CorpType = (props) => {
    const { corpType, setCorpType } = useContext(FormContext);

    const onSelect = (type) => () => {
        setCorpType(type);
    };

    useEffect(() => {
        sendEvent('step_corp_corporation_type');
    }, []);

    return (
        <>
            <div className="flex-grow">
                <StepBoxRadio
                    items={[
                        {
                            key: 'c-corp',
                            label: 'C Corporation',
                        },
                        {
                            key: 's-corp',
                            label: 'S Corporation',
                        },
                    ]}
                    onSelect={onSelect}
                    selectedItem={corpType}
                />
            </div>
            <NavigationButtons {...props} nextDisabled={!corpType} />
        </>

    );
};

export default CorpType;
