import React from 'react';
import { Typography } from 'antd';
import Content from '../Components/Content';

const { Title, Paragraph } = Typography;

const ThankYou = () => (
    <Content background="#fff">
        <div className="text-center">
            <div className="w-1/2  m-auto">
                <img className="w-full" src="/thank-you.jpg" alt="thank-you" />
            </div>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <Title className="m-0" level={3} style={{ margin: 0 }}>Thank You!</Title>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <Paragraph>We'll contact you soon!</Paragraph>
            <Paragraph>
                If you require any further information, feel free to contact us.
                {' '}
                <a href="mailto:support@incorp.today">support@incorp.today</a>
            </Paragraph>
        </div>

    </Content>
);

export default ThankYou;
