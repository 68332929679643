import React from 'react';
import { Button, Typography, Collapse } from 'antd';
import { Link } from 'react-router-dom';
import Content from '../Components/Content';

const { Panel } = Collapse;

const { Title } = Typography;

const Pricing = () => (
    <>
        <Content>
            <div className="text-center">
                <Title level={4}>ALL SERVICES, ONE PRICE</Title>
                <div className="flex flex-col justify-center items-center">
                    <Title>$399</Title>
                    <div>
                        <Button
                            type="primary"
                            shape="round"
                            size="large"
                            className="flex flex-row items-center"
                        >
                            <Link to="/get-started">
                                GET STARTED
                            </Link>

                        </Button>
                    </div>
                </div>
            </div>
        </Content>
        <Content>
            <div>
                <Title level={4}>What we do?</Title>
                <Collapse>
                    <Panel header="LLCs and Corps for US & Foreign Founders" key="1">
                        {/* eslint-disable-next-line max-len */}
                        <p>We support founders globally. LLC or C Corp? Don’t know the perfect fit? You don’t need to lose sleep over it—we exist because of you!</p>
                    </Panel>
                    <Panel header="US Tax Help" key="2">
                        {/* eslint-disable-next-line max-len */}
                        <p>Incorp.today bridges the gap between our customers and a certified tax professional to assist them with US taxes.</p>
                    </Panel>
                    <Panel header="EIN" key="3">
                        {/* eslint-disable-next-line max-len */}
                        <p>After your business formation, you have to apply for an Employer Identification Number (EIN). An EIN is a nine-digit number used to identify employer tax accounts. Our filling experts make the filling of your EIN application process quick and hassle-free.</p>
                    </Panel>
                    <Panel header="US Bank Account & Mailing Address" key="4">
                        {/* eslint-disable-next-line max-len */}
                        <p>We’ll set up a US Mailing address for your business and also a bank account.</p>
                    </Panel>
                    <Panel header="Post-incorporation Document" key="5">
                        {/* eslint-disable-next-line max-len */}
                        <p>Although the actual incorporation process is generally very fast and simple, typically involving the filing of a relatively short Certificate of Incorporation, the formation process requires additional paperwork. For us at Incorp.today, it is our duty to prepare the necessary legal document after incorporation.</p>
                    </Panel>
                </Collapse>
            </div>
        </Content>
        <Content>
            <div>
                <Title level={4}>F.A.Q</Title>
                <Collapse>
                    <Panel header="What is Incorp.today" key="1">
                        {/* eslint-disable-next-line max-len */}
                        <p>Incorp.today provides the framework for founders globally to launch their US-based businesses and thrive. They enjoy a range of benefits—from venture capital, startup tools, accelerators, US-based customers, tax benefits, and many more. We have simplified the process; there is no better time to launch than now.</p>
                    </Panel>
                    <Panel header="How long does incorporation take?" key="2">
                        {/* eslint-disable-next-line max-len */}
                        <p>Company formation takes around 6-8 working hours for most companies. Our company formation service is the quickest registration with instant notification by email and text.</p>
                    </Panel>
                    <Panel header="Why incorporating a startup in the US" key="3">
                        {/* eslint-disable-next-line max-len */}
                        <p>Setting up a company in the US is the first step in creating a global brand.</p>
                        <br />
                        {/* eslint-disable-next-line max-len */}
                        <p>The first step to creating a global brand is by incorporating a startup in the US. The U.S. offers government incentives and tax benefits. Businesses may explore new grounds that they don’t have access to in their home country. Another benefit is that the U.S. economy is growing, and as it does, the demand and sales of products increase.</p>
                    </Panel>
                    <Panel header="Can a Foreign citizen Incorporate a Business in the US without being a resident?" key="4">
                        {/* eslint-disable-next-line max-len */}
                        <p>No restriction comes with foreign ownership of businesses incorporated in the US. The procedure for forming a US company is the same for both US residents and their foreign counterparts.</p>
                    </Panel>
                    <Panel header="Will I Pay Taxes in the US?" key="5">
                        {/* eslint-disable-next-line max-len */}
                        <p>As a foreigner and a shareholder of a US company, you’ll not be liable to pay tax if your entity has no US-connected income. By selling products or services to the US market, you’re not subject to paying taxes. We recommend that you speak to a tax expert about your unique situation.</p>
                    </Panel>
                    <Panel header="Why a Registered agent?" key="6">
                        {/* eslint-disable-next-line max-len */}
                        <p>The registered agent accepts all mail and acts as the point of contact for the State’s office secretary. Incorp.today can be that agent. Your agent must be in the loop as regards changes to your telephone number or mailing address. It ensures that you can be reached when there’s a need for it.</p>
                    </Panel>
                    <Panel header="Do I require a visa to own a US business?" key="7">
                        {/* eslint-disable-next-line max-len */}
                        <p>You don’t need to be a US citizen or have a visa to have a US company. However, if you need to work in the US, you’ll require a valid US Visa.</p>
                    </Panel>
                </Collapse>
            </div>
        </Content>
    </>
);

export default Pricing;
