import React from 'react';
import { Button } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

const NavigationButtons = ({
    onNext, onPrev, nextDisabled, prevDisabled,
    nextButtonName, nextIsLoading,
}) => (
    <div className="flex justify-between mt-4">
        <Button
            type="primary"
            shape="round"
            size="large"
            disabled={prevDisabled}
            onClick={onPrev}
            className="flex flex-row items-center"
        >
            <LeftOutlined />
            Back
        </Button>
        <Button
            loading={nextIsLoading}
            type="primary"
            shape="round"
            size="large"
            disabled={nextDisabled}
            onClick={onNext}
            className="flex flex-row items-center"
        >
            {nextButtonName}
            <RightOutlined />
        </Button>
    </div>
);

NavigationButtons.defaultProps = {
    nextButtonName: 'Next',
    nextIsLoading: false,
};

export default NavigationButtons;
