import React, { useState } from 'react';
import { FormContext } from '../FormContext';

import Main from '../Main';

const defaultValues = {
    step: 0,
    subStep: 0,
    companyType: '',
    corpType: '',
    state: '',
    owners: [
        {
            firstName: '',
            lastName: '',
            ownership: '',
        },
    ],
    LLCCompanyDetails: {
        name: '',
        description: '',
    },
    LLCAddress: {},
    corpBusinessInfo: {
        name: '',
        description: '',
    },
    corpShares: {
        quantity: 10000000,
        perValue: 0.00001,
    },
    shareholders: [
        {
            firstName: '',
            lastName: '',
        },
    ],
    officers: {
        seo: '',
        president: '',
        sfo: '',
        secretary: '',
    },
    personalInfo: {},
};

function CompanyForm() {
    const [step, setStep] = useState(defaultValues.step);
    const [subStep, setSubStep] = useState(defaultValues.subStep);
    const [companyType, setCompanyType] = useState(defaultValues.companyType);
    const [corpType, setCorpType] = useState(defaultValues.corpType);
    const [state, setState] = useState(defaultValues.state);
    const [owners, setOwners] = useState(defaultValues.owners);
    const [LLCCompanyDetails, setLLCCompanyDetails] = useState(defaultValues.LLCCompanyDetails);
    const [LLCAddress, setLLCAddress] = useState(defaultValues.LLCAddress);

    const [corpBusinessInfo, setCorpBusinessInfo] = useState(defaultValues.corpBusinessInfo);

    const [corpShares, setCorpShares] = useState(defaultValues.corpShares);

    const [shareholders, setShareholders] = useState(defaultValues.shareholders);

    const [officers, setOfficers] = useState(defaultValues.officers);

    const [personalInfo, setPersonalInfo] = useState(defaultValues.personalInfo);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const resetData = () => {
        setStep(defaultValues.step);
        setSubStep(defaultValues.subStep);
        setCompanyType(defaultValues.companyType);
        setCorpType(defaultValues.corpType);
        setState(defaultValues.state);
        setOwners(defaultValues.owners);
        setLLCCompanyDetails(defaultValues.LLCCompanyDetails);
        setLLCAddress(defaultValues.LLCAddress);
        setCorpBusinessInfo(defaultValues.corpBusinessInfo);
        setCorpShares(defaultValues.corpShares);
        setShareholders(defaultValues.shareholders);
        setOfficers(defaultValues.officers);
        setPersonalInfo(defaultValues.personalInfo);
        setIsSubmitting(defaultValues.isSubmitting);
    };

    const providerValue = {
        step,
        setStep,
        subStep,
        setSubStep,
        companyType,
        setCompanyType,
        state,
        setState,
        corpType,
        setCorpType,
        owners,
        setOwners,
        LLCCompanyDetails,
        setLLCCompanyDetails,
        LLCAddress,
        setLLCAddress,
        corpBusinessInfo,
        setCorpBusinessInfo,
        corpShares,
        setCorpShares,
        shareholders,
        setShareholders,
        officers,
        setOfficers,
        personalInfo,
        setPersonalInfo,
        isSubmitting,
        setIsSubmitting,
        resetData,
    };

    return (
        <FormContext.Provider value={providerValue}>
            <Main />
        </FormContext.Provider>
    );
}

export default CompanyForm;
