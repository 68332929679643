import React, { useContext } from 'react';
import { Steps, Typography } from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import CompanyType from '../Steps/CompanyType';
import State from '../Steps/State';
import CorpType from '../Steps/Corp/CorpType';
import BusinessInfo from '../Steps/Corp/BusinessInfo';
import Shares from '../Steps/Corp/Shares';
import Shareholders from '../Steps/Corp/Shareholders';
import Officers from '../Steps/Corp/Officers';
import CompanyInfo from '../Steps/LLC/CompanyInfo';
import Owners from '../Steps/LLC/Owners';
import MailingAddress from '../Steps/LLC/MailingAddress';
import PersonalInfo from '../Steps/PersonalInfo';
import { FormContext } from '../FormContext';
import Content from '../Components/Content';
import { sendEvent } from '../Helpers/analytics';

const states = require('../states.json');

const { Title } = Typography;

const GetStarted = () => {
    const history = useHistory();
    const {
        step,
        setStep,
        subStep,
        setSubStep,
        companyType,
        state,
        corpType,
        owners,
        LLCCompanyDetails,
        LLCAddress,
        corpBusinessInfo,
        corpShares,
        shareholders,
        officers,
        setIsSubmitting,
        resetData,
    } = useContext(FormContext);
    const steps = [
        {
            stepTitle: 'Company Type',
            children: [{
                title: 'Choose a company type',
                component: CompanyType,
            }],
        },
        {
            stepTitle: 'State',
            children: [{
                title: 'Choose a state',
                component: State,
            }],
        },
        {
            stepTitle: 'Project Info',
            children: (() => {
                if (companyType === 'corporation') {
                    return [
                        {
                            title: 'How will your company be taxed?',
                            component: CorpType,
                        },
                        {
                            component: BusinessInfo,
                            title: 'Tell us about your business',
                        },
                        {
                            component: Shares,
                            title: 'Number of shares authorized',
                        },
                        {
                            component: Shareholders,
                            title: 'Shareholders',
                        },
                        {
                            component: Officers,
                            title: 'Officers',
                        },
                    ];
                } if (companyType === 'llc') {
                    return [
                        {
                            component: CompanyInfo,
                            title: 'Tell us about your business',
                        },
                        {
                            component: Owners,
                            title: 'Owners',
                        },
                        {
                            component: MailingAddress,
                            title: `Applicant’s mailing address
                            (It doesn't have to be in the U.S.)`,
                        },
                    ];
                }
                return [];
            })(),
        },
        {
            stepTitle: 'Personal Info',
            children: [{
                title: 'Personal Info',
                component: PersonalInfo,
            }],
        },
    ];

    const nextStep = () => {
        if (steps[step].children.length - 1 !== subStep) {
            setSubStep(subStep + 1);
        } else {
            setSubStep(0);
            setStep(step + 1);
        }
    };

    const prevStep = () => {
        if (!step && !subStep) return;
        if (subStep > 0) {
            setSubStep(subStep - 1);
        } else {
            const previousStepIndex = step - 1;
            setStep(previousStepIndex);
            setSubStep(steps[previousStepIndex].children.length - 1);
        }
    };

    const getStepComponent = () => steps[step].children[subStep].component;
    const getStepComponentTitle = () => {
        if (steps[step].children) {
            return steps[step].children[subStep].title;
        }
        return null;
    };

    const StepComponent = getStepComponent();

    const onSubmit = async (personalInfo) => {
        setIsSubmitting(true);
        try {
            await axios.post('https://qe.tax/incorp', {
                companyType,
                state: _.find(states, (s) => s.abbreviation === state).name,
                corpType,
                owners,
                LLCCompanyDetails,
                LLCAddress,
                corpBusinessInfo,
                corpShares,
                shareholders,
                officers,
                personalInfo,
            });
            resetData();
            sendEvent('flow_finish');
            history.push('/thank-you');
        } catch (err) {
            alert('Something went wrong! Please try again!'); // eslint-disable-line
        } finally {
            setIsSubmitting(false);
        }
    };
    return (
        <Content>
            <Steps current={step}>
                {steps.map(({ stepTitle }) => (
                    <Steps.Step title={stepTitle} />
                ))}
            </Steps>
            <StepTitleWrapper>
                <Center>
                    <Title level={3}>{getStepComponentTitle()}</Title>
                </Center>
            </StepTitleWrapper>
            <FormWrapper>
                <StepComponent
                    onNext={nextStep}
                    onPrev={prevStep}
                    onSubmit={onSubmit}
                />
            </FormWrapper>
        </Content>
    );
};

const StepTitleWrapper = styled.div`
  margin-top: 48px;
`;

const Center = styled.div`
  // text-align: center;
`;

const FormWrapper = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  width: 100%:
  flex: 1;
  min-height: 300px;

`;

export default GetStarted;
