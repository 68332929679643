import React from 'react';
import { Button, Typography } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Content from '../Components/Content';

const { Title } = Typography;

const Landing = () => (
    <Content width="90%" background="#fff">
        <div className="flex flex-row items-center">
            <div className="flex-1 flex flex-col">
                <Title style={{ margin: 0 }}>Focus on what you Love Doing</Title>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <Title className="m-0" level={3} style={{ margin: 0 }}>We'll Take Care of the paperwork</Title>
                <div className="flex flex-row mt-20">
                    <Button
                        type="primary"
                        shape="round"
                        size="large"
                        className="flex flex-row items-center mr-5"
                    >
                        <Link to="/get-started">
                            GET STARTED
                        </Link>

                    </Button>
                    <Button
                        type="secondary"
                        shape="round"
                        size="large"
                        className="flex flex-row items-center"
                    >
                        <Link to="/how-it-works">
                            <div className="flex items-center">
                                <spna>HOW IT WORKS?</spna>
                                <PlayCircleOutlined className="ml-1" />
                            </div>

                        </Link>
                    </Button>
                </div>

            </div>
            <div className="w-3/5">
                <img className="w-full" alt="business idea" src="/landing.jpg" />
            </div>
        </div>
    </Content>

);

export default Landing;
