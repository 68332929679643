import styled from 'styled-components';

const Content = styled.div`
  background: ${(props) => props.background || '#fafafa'};
  padding: 24px;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: ${(props) => props.width || '60%'};
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`;

export default Content;
