import React, { useContext, useEffect } from 'react';
import {
    Button,
    Form, Input,
} from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { FormContext } from '../../FormContext';
import NavigationButtons from '../../Components/NavigationButtons';
import { sendEvent } from '../../Helpers/analytics';

const Shareholders = (props) => {
    const [form] = Form.useForm();
    const { shareholders, setShareholders } = useContext(FormContext);

    const onNext = () => {
        form
            .validateFields()
            .then((args) => args.shareholders)
            .then((values) => values.map(({ firstName, lastName }) => ({
                firstName, lastName,
            })))
            .then((values) => {
                setShareholders(values);
                props.onNext();
            });
    };

    useEffect(() => {
        sendEvent('step_corp_shareholders');
    }, []);

    return (
        <>
            <div className="flex-grow">
                <Form layout="vertical" form={form} initialValues={{ shareholders }}>
                    <Form.List name="shareholders">
                        {(fields, { add, remove }) => (
                            <>
                                {
                                    fields.map((field) => (
                                        <div className="flex items-center" key={field.key}>
                                            <Form.Item
                                                {...field}
                                                rules={[{ required: true, message: 'Please enter first name' }]}
                                                label="First Name"
                                                className="flex-grow mx-1"
                                                name={[field.name, 'firstName']}
                                                fieldKey={[field.fieldKey, 'firstName']}
                                            >
                                                <Input placeholder="Enter first name" size="large" />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                rules={[{ required: true, message: 'Please enter last name' }]}
                                                label="Last Name"
                                                required
                                                className="flex-grow mx-1"
                                                name={[field.name, 'lastName']}
                                                fieldKey={[field.fieldKey, 'lastName']}
                                            >
                                                <Input placeholder="Enter last name" size="large" />
                                            </Form.Item>
                                            <CloseOutlined
                                                className="ml-2 cursor-pointer text-base"
                                                onClick={() => {
                                                    remove(field.name);
                                                }}
                                            />
                                        </div>

                                    ))
                                }
                                <Form.Item>
                                    <Button
                                        onClick={add}
                                        shape="round"
                                        size="large"
                                        className="flex flex-row items-center"
                                    >
                                        <PlusOutlined />
                                        Add Shareholder
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </div>
            <NavigationButtons {...props} onNext={onNext} />
        </>

    );
};

export default Shareholders;
