import React, { useContext, useEffect } from 'react';
import StepBoxRadio from '../Components/StepBoxRadio';
import { FormContext } from '../FormContext';
import NavigationButtons from '../Components/NavigationButtons';
import { sendEvent } from '../Helpers/analytics';

const CompanyType = (props) => {
    const { companyType, setCompanyType } = useContext(FormContext);

    const onSelect = (type) => () => {
        setCompanyType(type);
    };

    useEffect(() => {
        sendEvent('step_company_type');
    }, []);

    return (
        <>
            <div className="flex-grow">
                <StepBoxRadio
                    items={[
                        {
                            key: 'llc',
                            label: 'LLC',
                        },
                        {
                            key: 'corporation',
                            label: 'Corporation',
                        },
                    ]}
                    onSelect={onSelect}
                    selectedItem={companyType}
                />
            </div>
            <NavigationButtons {...props} nextDisabled={!companyType} prevDisabled />
        </>

    );
};

export default CompanyType;
