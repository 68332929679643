import React, { useContext, useEffect } from 'react';
import { Form, Input } from 'antd';
import NavigationButtons from '../../Components/NavigationButtons';
import { FormContext } from '../../FormContext';
import { sendEvent } from '../../Helpers/analytics';

const MailingAddress = (props) => {
    const [form] = Form.useForm();
    const { LLCAddress, setLLCAddress } = useContext(FormContext);
    const onNext = () => {
        form.validateFields().then((values) => {
            setLLCAddress(values);
            props.onNext();
        });
    };

    useEffect(() => {
        sendEvent('step_llc_mailing_address');
    }, []);

    return (
        <>
            <Form layout="vertical" className="flex-grow" form={form} initialValues={LLCAddress}>
                <Form.Item
                    name="country"
                    rules={[{ required: true, message: 'Please enter country' }]}
                    label="Country"
                >
                    <Input placeholder="Choose your country" size="large" />
                </Form.Item>
                <Form.Item
                    name="state"
                    rules={[{ required: true, message: 'Please enter state' }]}
                    label="State"
                    required
                >
                    <Input placeholder="Enter your state" size="large" />
                </Form.Item>
                <Form.Item
                    name="city"
                    rules={[{ required: true, message: 'Please enter city' }]}
                    label="City"
                    required
                >
                    <Input placeholder="Enter your city" size="large" />
                </Form.Item>
                <Form.Item
                    name="address_1"
                    rules={[{ required: true, message: 'Please enter address' }]}
                    label="Address line 1"
                    required
                >
                    <Input placeholder="Enter your address" size="large" />
                </Form.Item>
                <Form.Item
                    name="address_2"
                    label="Address line 2"
                >
                    <Input placeholder="Enter your address" size="large" />
                </Form.Item>
                <Form.Item
                    name="zip"
                    rules={[{ required: true, message: 'Please enter zip code' }]}
                    label="Zip code"
                    required
                >
                    <Input placeholder="Enter your zip code" size="large" />
                </Form.Item>
            </Form>
            <NavigationButtons {...props} onNext={onNext} />
        </>

    );
};

export default MailingAddress;
