import React, { useContext, useEffect } from 'react';
import { Form, Select } from 'antd';
import states from '../states.json';
import { FormContext } from '../FormContext';
import NavigationButtons from '../Components/NavigationButtons';
import { sendEvent } from '../Helpers/analytics';

const { Option } = Select;

const State = (props) => {
    const { state, setState } = useContext(FormContext);

    useEffect(() => {
        sendEvent('step_state');
    }, []);

    return (
        <>
            <Form layout="vertical" className="flex-grow">
                <Form.Item label="Select a state" required>
                    <Select size="large" style={{ minWidth: 300 }} value={state} onSelect={setState}>
                        <Option value="" disabled>- Choose -</Option>
                        {states.map(({ name, abbreviation }) => (
                            <Option value={abbreviation}>{name}</Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
            <NavigationButtons {...props} nextDisabled={!state} />
        </>

    );
};

export default State;
