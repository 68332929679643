import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const NavigationMenu = () => {
    const location = useLocation();
    return (
        <Menu className="flex-gro" theme="light" mode="horizontal" defaultSelectedKeys={[location.pathname]}>
            <Menu.Item key="/get-started">
                <Link to="/get-started">Get Started</Link>
            </Menu.Item>
            <Menu.Item key="/how-it-works">
                <Link to="/how-it-works">How it works</Link>
            </Menu.Item>
            <Menu.Item key="/pricing">
                <Link to="/pricing">Pricing</Link>
            </Menu.Item>
        </Menu>
    );
};

export default NavigationMenu;
