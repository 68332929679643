import { Form, InputNumber } from 'antd';
import React, { useContext, useEffect } from 'react';
import NavigationButtons from '../../Components/NavigationButtons';
import { FormContext } from '../../FormContext';
import { sendEvent } from '../../Helpers/analytics';

const Shares = (props) => {
    const [form] = Form.useForm();
    const { corpShares, setCorpShares } = useContext(FormContext);

    const onNext = () => {
        form.validateFields().then((values) => {
            setCorpShares(values);
            props.onNext();
        }).catch(() => 0);
    };

    useEffect(() => {
        sendEvent('step_corp_shares');
    }, []);

    return (
        <>
            <div className="flex-grow">
                <Form layout="vertical" style={{ width: '100%' }} form={form} initialValues={corpShares}>
                    <Form.Item
                        name="quantity"
                        label="Number of shares"
                        rules={[{ required: true, message: 'Please enter amount' }]}
                    >
                        <InputNumber placeholder="Enter amount" size="large" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="perValue"
                        label="Per value"
                        rules={[{ required: true, message: 'Please enter amount' }]}
                    >
                        <InputNumber placeholder="Enter amount" size="large" style={{ width: '100%' }} />
                    </Form.Item>
                </Form>
            </div>
            <NavigationButtons {...props} onNext={onNext} />
        </>
    );
};

export default Shares;
