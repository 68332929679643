import {
    Form, Input,
} from 'antd';
import React, { useContext, useEffect } from 'react';
import NavigationButtons from '../../Components/NavigationButtons';
import { FormContext } from '../../FormContext';
import { sendEvent } from '../../Helpers/analytics';

const Officers = (props) => {
    const [form] = Form.useForm();
    const { officers, setOfficers } = useContext(FormContext);

    const onNext = () => {
        form.validateFields().then((values) => {
            setOfficers(values);
            props.onNext();
        }).catch(() => 0);
    };

    useEffect(() => {
        sendEvent('step_corp_officers');
    }, []);

    return (
        <>
            <Form layout="vertical" style={{ width: '100%' }} form={form} initialValues={officers}>
                <Form.Item
                    name="ceo"
                    label="CEO"
                    rules={[{ required: true, message: 'Please select CEO' }]}
                >
                    <Input placeholder="Enter CEO full name" size="large" />
                </Form.Item>
                <Form.Item
                    name="president"
                    label="President"
                    rules={[{ required: true, message: 'Please select President' }]}
                >
                    <Input placeholder="Enter President full name" size="large" />
                </Form.Item>
                <Form.Item
                    name="cfo"
                    label="CFO"
                    rules={[{ required: true, message: 'Please select CFO' }]}
                >
                    <Input placeholder="Enter CFO full name" size="large" />
                </Form.Item>
                <Form.Item
                    name="secretary"
                    label="Secretary"
                    rules={[{ required: true, message: 'Please select Secretary' }]}
                >
                    <Input placeholder="Enter Secretary full name" size="large" />
                </Form.Item>
            </Form>
            <NavigationButtons {...props} onNext={onNext} />
        </>
    );
};

export default Officers;
