import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Form, Input, InputNumber, Alert,
} from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { FormContext } from '../../FormContext';
import NavigationButtons from '../../Components/NavigationButtons';
import { sendEvent } from '../../Helpers/analytics';

const Owners = (props) => {
    const [form] = Form.useForm();
    const [alertError, setAlertError] = useState(null);
    const { owners, setOwners } = useContext(FormContext);

    const onNext = () => {
        form
            .validateFields()
            .then((args) => args.owners)
            .then((values) => values.map(({ firstName, lastName, ownership }) => ({
                firstName, lastName, ownership,
            })))
            .then((values) => {
                const totalOwnership = _.chain(values)
                    .map(({ ownership }) => ownership)
                    .sum()
                    .value();
                if (totalOwnership !== 100) {
                    setAlertError('Total of percentages must equal 100');
                } else {
                    setAlertError(null);
                    setOwners(values);
                    props.onNext();
                }
            });
    };

    useEffect(() => {
        sendEvent('step_llc_owners');
    }, []);

    return (
        <>
            <div className="flex-grow">
                <Form layout="vertical" form={form} initialValues={{ owners }}>
                    {!!alertError && (
                        <Form.Item>
                            <Alert message={alertError} type="error" />
                        </Form.Item>
                    )}
                    <Form.List name="owners">
                        {(fields, { add, remove }) => (
                            <>
                                {
                                    fields.map((field) => (
                                        <div className="flex items-center" key={field.key}>
                                            <Form.Item
                                                {...field}
                                                rules={[{ required: true, message: 'Please enter first name' }]}
                                                label="First Name"
                                                className="flex-grow mx-1"
                                                name={[field.name, 'firstName']}
                                                fieldKey={[field.fieldKey, 'firstName']}
                                            >
                                                <Input placeholder="Enter first name" size="large" />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                rules={[{ required: true, message: 'Please enter last name' }]}
                                                label="Last Name"
                                                className="flex-grow mx-1"
                                                name={[field.name, 'lastName']}
                                                fieldKey={[field.fieldKey, 'lastName']}

                                            >
                                                <Input placeholder="Enter last name" size="large" />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                rules={[{ required: true, message: 'Please enter ownership' }]}
                                                label="Ownership"
                                                required
                                                className="mx-1"
                                                name={[field.name, 'ownership']}
                                                fieldKey={[field.fieldKey, 'ownership']}
                                            >
                                                <InputNumber placeholder="0-100%" size="large" min={0} max={100} />
                                            </Form.Item>
                                            <CloseOutlined
                                                className="ml-2 cursor-pointer text-base"
                                                onClick={() => {
                                                    remove(field.name);
                                                }}
                                            />
                                        </div>

                                    ))
                                }
                                <Form.Item>
                                    <Button
                                        onClick={add}
                                        shape="round"
                                        size="large"
                                        className="flex flex-row items-center"
                                    >
                                        <PlusOutlined />
                                        Add Owner
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </div>
            <NavigationButtons {...props} onNext={onNext} />
        </>

    );
};

export default Owners;
