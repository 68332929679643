import { Form, Input } from 'antd';
import React, { useContext, useEffect } from 'react';
import NavigationButtons from '../../Components/NavigationButtons';
import { FormContext } from '../../FormContext';
import { sendEvent } from '../../Helpers/analytics';

const BusinessInfo = (props) => {
    const [form] = Form.useForm();
    const { corpBusinessInfo, setCorpBusinessInfo } = useContext(FormContext);

    const onNext = () => {
        form.validateFields().then((values) => {
            setCorpBusinessInfo(values);
            props.onNext();
        }).catch(() => 0);
    };

    useEffect(() => {
        sendEvent('step_corp_business_info');
    }, []);

    return (
        <>
            <Form layout="vertical" style={{ width: '100%' }} form={form} initialValues={corpBusinessInfo}>
                <Form.Item
                    name="name"
                    label="Company name"
                    rules={[{ required: true, message: 'Please enter your business name' }]}
                >
                    <Input placeholder="Enter your company name" size="large" />
                </Form.Item>
                <Form.Item
                    label="Describe your project in a few words"
                    name="description"
                    rules={[{ required: true, message: 'Please enter a description of your company' }]}
                >
                    <Input.TextArea
                        placeholder="Enter a description of your company"
                        rows="4"
                    />
                </Form.Item>
            </Form>
            <NavigationButtons {...props} onNext={onNext} />
        </>
    );
};

export default BusinessInfo;
