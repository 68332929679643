import React, { useContext, useEffect } from 'react';
import { Form, Input } from 'antd';
import { FormContext } from '../FormContext';
import NavigationButtons from '../Components/NavigationButtons';
import { sendEvent } from '../Helpers/analytics';

const PersonalInfo = (props) => {
    const [form] = Form.useForm();
    const { personalInfo, setPersonalInfo, isSubmitting } = useContext(FormContext);

    const onNext = () => {
        form.validateFields().then((values) => {
            setPersonalInfo(values);
            props.onSubmit(values);
        }).catch(() => 0);
    };

    useEffect(() => {
        sendEvent('step_personal_info');
    }, []);

    return (
        <>
            <Form layout="vertical" form={form} initialValues={personalInfo} style={{ width: '100%' }}>
                <Form.Item label="Applicant's name" required>
                    <div className="flex">
                        <Form.Item
                            className="flex-grow mr-1"
                            name="firstName"
                            rules={[{ required: true, message: 'Please enter First Name' }]}
                        >
                            <Input placeholder="Enter your first name" size="large" />
                        </Form.Item>
                        <Form.Item
                            className="flex-grow ml-1"
                            name="lastName"
                            rules={[{ required: true, message: 'Please enter Last Name' }]}
                        >
                            <Input placeholder="Enter your first name" size="large" />
                        </Form.Item>
                    </div>
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please enter your email' }]}
                >
                    <Input placeholder="Enter your email" size="large" />
                </Form.Item>
                <Form.Item
                    label="Phone"
                    name="phone"
                    rules={[{ required: true, message: 'Please enter phone number' }]}
                >
                    <Input placeholder="Enter your phone" size="large" />
                </Form.Item>
                <Form.Item
                    label="SSN or ITIN (optional)"
                    name="ssn"
                    rules={[{ required: false }]}
                >
                    <Input placeholder="xxx-xx-xxxx" size="large" />
                </Form.Item>
            </Form>
            <NavigationButtons {...props} onNext={onNext} nextButtonName="Submit" nextIsLoading={isSubmitting} />
        </>

    );
};

export default PersonalInfo;
