import React from 'react';
import styled from 'styled-components';
import { blue } from '@ant-design/colors';
import { Typography } from 'antd';
import TextCenter from './Center';

const StepBoxRadio = ({ items, selectedItem, onSelect }) => (
    <Wrapper>
        {
            items.map((item) => (
                <Item
                    key={item.key}
                    onClick={onSelect(item.key)}
                    isSelected={selectedItem === item.key}
                >
                    <TextCenter>
                        <ItemTitle
                            level={4}
                            isSelected={selectedItem === item.key}
                        >
                            {item.label}
                        </ItemTitle>
                    </TextCenter>
                </Item>
            ))
        }
    </Wrapper>
);

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Item = styled.div`
    border-radius: 5px;
    flex: 1;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    padding: 24px;
    justify-content: center;
    align-items: center;
    background: ${({ isSelected }) => (isSelected ? blue.primary : '#f0f0f0')};
`;

const ItemTitle = styled(Typography.Title)`
    color: ${({ isSelected }) => (isSelected ? '#fff' : 'rgba(0, 0, 0, 0.85)')}!important;
`;

export default StepBoxRadio;
