import React from 'react';
import { Button, Typography, Collapse } from 'antd';
import { Link } from 'react-router-dom';
import Content from '../Components/Content';

const { Panel } = Collapse;

const { Title } = Typography;

const HowItWorks = () => (
    <>
        <Content>
            <div className="flex flex-col justify-center items-center">
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe
                    className="flex-1 w-full"
                    width="!00%"
                    height="515"
                    src="https://www.youtube.com/embed/XnHoS7IIpAk"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
                <div className="mt-5">
                    <Button
                        type="primary"
                        shape="round"
                        size="large"
                        className="flex flex-row items-center"
                    >
                        <Link to="/get-started">
                            GET STARTED
                        </Link>

                    </Button>
                </div>
            </div>

        </Content>

        <Content>
            <div>
                <Title level={4}>F.A.Q</Title>
                <Collapse>
                    <Panel header="What is Incorp.today" key="1">
                        {/* eslint-disable-next-line max-len */}
                        <p>Incorp.today provides the framework for founders globally to launch their US-based businesses and thrive. They enjoy a range of benefits—from venture capital, startup tools, accelerators, US-based customers, tax benefits, and many more. We have simplified the process; there is no better time to launch than now.</p>
                    </Panel>
                    <Panel header="How long does incorporation take?" key="2">
                        {/* eslint-disable-next-line max-len */}
                        <p>Company formation takes around 6-8 working hours for most companies. Our company formation service is the quickest registration with instant notification by email and text.</p>
                    </Panel>
                    <Panel header="Why incorporating a startup in the US" key="3">
                        {/* eslint-disable-next-line max-len */}
                        <p>Setting up a company in the US is the first step in creating a global brand.</p>
                        <br />
                        {/* eslint-disable-next-line max-len */}
                        <p>The first step to creating a global brand is by incorporating a startup in the US. The U.S. offers government incentives and tax benefits. Businesses may explore new grounds that they don’t have access to in their home country. Another benefit is that the U.S. economy is growing, and as it does, the demand and sales of products increase.</p>
                    </Panel>
                    <Panel header="Can a Foreign citizen Incorporate a Business in the US without being a resident?" key="4">
                        {/* eslint-disable-next-line max-len */}
                        <p>No restriction comes with foreign ownership of businesses incorporated in the US. The procedure for forming a US company is the same for both US residents and their foreign counterparts.</p>
                    </Panel>
                    <Panel header="Will I Pay Taxes in the US?" key="5">
                        {/* eslint-disable-next-line max-len */}
                        <p>As a foreigner and a shareholder of a US company, you’ll not be liable to pay tax if your entity has no US-connected income. By selling products or services to the US market, you’re not subject to paying taxes. We recommend that you speak to a tax expert about your unique situation.</p>
                    </Panel>
                    <Panel header="Why a Registered agent?" key="6">
                        {/* eslint-disable-next-line max-len */}
                        <p>The registered agent accepts all mail and acts as the point of contact for the State’s office secretary. Incorp.today can be that agent. Your agent must be in the loop as regards changes to your telephone number or mailing address. It ensures that you can be reached when there’s a need for it.</p>
                    </Panel>
                    <Panel header="Do I require a visa to own a US business?" key="7">
                        {/* eslint-disable-next-line max-len */}
                        <p>You don’t need to be a US citizen or have a visa to have a US company. However, if you need to work in the US, you’ll require a valid US Visa.</p>
                    </Panel>
                </Collapse>
            </div>
        </Content>
    </>
);

export default HowItWorks;
