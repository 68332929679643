import React from 'react';
import {
    Layout, Typography,
} from 'antd';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
} from 'react-router-dom';
import styled from 'styled-components';
import GetStarted from './Routes/GetStarted';
import Landing from './Routes/Landing';
import NavigationMenu from './Layout/NavigationMenu';
import ThankYou from './Routes/ThankYou';
import Pricing from './Routes/Pricing';
import HowItWorks from './Routes/HowItWorks';

function Main() {
    return (
        <Router>
            <Layout style={{ minHeight: '100vh', background: '#fff' }}>
                <Layout.Header theme="light" style={{ background: 'white' }} className="flex items-center">
                    <div className="logo mr-5">
                        <Typography.Title
                            level={4}
                            style={{ margin: 0 }}
                        >
                            <Link to="/">
                                <img style={{ width: 200 }} src="/logo.png" alt="logo" />
                            </Link>
                        </Typography.Title>
                    </div>
                    <NavigationMenu />
                </Layout.Header>
                <LayoutContent>
                    <Switch>
                        <Route path="/" exact>
                            <Landing />
                        </Route>
                        <Route path="/get-started">
                            <GetStarted />
                        </Route>
                        <Route path="/thank-you">
                            <ThankYou />
                        </Route>
                        <Route path="/pricing">
                            <Pricing />
                        </Route>
                        <Route path="/how-it-works">
                            <HowItWorks />
                        </Route>
                    </Switch>
                </LayoutContent>
                <Layout.Footer style={{ textAlign: 'center' }}>
                    Incorp Today ©
                    {(new Date().getFullYear())}
                </Layout.Footer>
            </Layout>
        </Router>

    );
}

const LayoutContent = styled(Layout.Content)`
  padding: 0 50px;
  height: 100%;
  @media (max-width: 768px) {
    padding: 0 0;
  }
`;

export default Main;
